@import url('https://fonts.cdnfonts.com/css/dyson-modern');

.moto-main-container {
  background: url(../../assets/home-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: white;
  height: 100vh;
  display: flex;
  padding: 0 1rem;
}

.moto-inner-container {
  margin: 4% auto;
  background-color: rgba(0, 0, 0, 0.64);
  border-radius: 50px;
  width: 75vw;
  padding: 2% 4%;
  border: 2px solid white;
  text-align: center;
}

.latest-model-title {
  font-size: 3vw;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.latest-models-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-height: 60vh;
  overflow-y: auto;
}

.motorcycle-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid black;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.motorcycle-item:hover {
  transform: scale(1.1);
}

.single-motor-list {
  width: 300px;
  margin-bottom: 2rem;
  background-color: #fff;
  border-radius: 2rem;
  color: black;
  border: 4px solid #e35e0a;
  box-shadow: 0 0 5px #ddb49b, 0 0 10px #ec8849, 0 0 20px #e35e0a, 0 0 40px;
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    box-shadow:
      0 0 2px #d89b74,
      0 0 8px #f47e35,
      0 0 10px #e35e0a,
      0 0 20px #e35e0a;
  }

  to {
    box-shadow:
      0 0 5px #e35e0a,
      0 0 10px #e35e0a,
      0 0 15px #e35e0a,
      0 0 20px #e35e0a;
  }
}

.single-motor-image {
  width: 400px;
  object-fit: scale-down;
}

.single-motor-name {
  font-size: 24px;
  font-weight: bold;
  padding: 4%;
  background-color: black;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  color: white;
  margin-bottom: 0;
}

.single-motor-model {
  font-size: 20px;
  font-family: 'Dyson Sans Modern', sans-serif;
  color: #f7f7f7;
  font-weight: bold;
  padding: 4%;
  background-color: black;
}

.single-motor-description {
  font-size: 1vw;
  font-weight: 600;
  margin: 2%;
}

.single-motor-socials {
  width: 60%;
  display: flex;
  justify-content: space-around;
  margin: 20px auto;
}

.single-motor-socials img {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .moto-inner-container {
    width: 70vw;
  }

  .latest-model-title {
    font-size: 5vw;
  }

  .latest-models-list {
    max-height: 80vh;
  }

  .motorcycle-item {
    width: 100%;
  }

  .single-motor-list {
    width: 90%;
  }

  .single-motor-image {
    width: 100%;
  }

  .single-motor-name {
    font-size: 20px;
  }

  .single-motor-model {
    font-size: 16px;
  }

  .single-motor-description {
    font-size: 14px;
  }

  .single-motor-socials {
    width: 80%;
  }
}

@media (min-width: 1032px) {
  .latest-model-title {
    font-size: 3vw;
    font-weight: bold;
    margin-top: 2px;
    margin-bottom: 15px;
    text-align: center;
  }

  .latest-models-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    overflow-y: auto;
  }
}

@media (max-width: 767px) and (min-width: 400px) {
  /* CSS rules for screen widths between 400px and 767px */

  /* Add your CSS styles here */
  .single-motor-list {
    width: 75%;
    margin-bottom: 4vw;
    max-width: 300px;
  }
}

@media (min-width: 1320px) {
  .latest-model-title {
    font-size: 3vw;
    font-weight: bold;
    margin-top: 2px;
    margin-bottom: 15px;
    text-align: center;
  }

  .latest-models-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    overflow-y: auto;
    height: 670px;
  }
}
