/* Reservation page styles */
.reservation-list-container {
  background: url(../../assets/Reserve-list.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100vh;
  border: 0.2px solid #00000028;
}

/* Page container */
.reservation-list {
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  background-color: #0000009e;
}

/* Heading */
.reservation-list-title {
  margin-top: 4%;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
}

/* Table styles */
.table-responsive {
  overflow-x: auto;
  max-width: 100%;
}

.reservation-table {
  border-collapse: collapse;
  width: 100%;
  max-width: 800px;
  margin-bottom: 50px;
  font-size: 1.2rem;
}

.res-thead {
  background-color: #f35d00;
  color: #fff;
}

.res-th,
.res-td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.res-th:first-child,
.res-td:first-child {
  border-left: 1px solid #ddd;
}

.res-th:last-child,
.res-td:last-child {
  border-right: 1px solid #ddd;
  text-align: left;
}

/* Button styles */
.res-del-btn {
  color: rgb(255, 85, 0);
  font-size: 1.7rem;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  margin-left: 0;
  text-align: left;
}

.res-del-btn:hover {
  color: #f81900;
  font-size: 1.8rem;
}

/* Media Query for screens less than 768px */
@media (max-width: 768px) {
  .reservation-list {
    margin-top: 5%;
  }

  .reservation-list-title {
    font-size: 2rem;
  }

  .reservation-table {
    font-size: 1rem;
  }

  .res-del-btn {
    font-size: 1.5rem;
  }
}
