.reservation-container {
  border: 0.5px solid #ffffff94;
  background: url(../../assets/Reserve\ bg.jpg);
  background-color: #e3a20a53;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}

.reservation-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60vw;
  margin: 10% auto;
  padding: 3%;
  background: #230b0093;
  border: 2px solid #de4a00;
  border-radius: 9px;
}

.reservation-form label {
  font-size: calc(0.6rem + 1vw);
  font-weight: bold;
  color: white;
  line-height: 2rem;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.reservation-form input,
.reservation-form select {
  width: 100%;
  font-size: calc(0.6rem + 1vw);
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 7px;
  color: #fff;
  outline: none;
  background: rgba(224, 219, 219, 0.245);
  font-family: 'Trebuchet MS', sans-serif;
}

.reservation-form input:focus,
.reservation-form select:focus {
  outline: none;
  box-shadow: 0 0 0 2px #ff8846;
  color: #000;
}

.reservation-form button {
  color: #fff;
  background-color: #e35e0a;
  font-weight: 700;
  height: 60px;
  padding: 10px 30px;
  width: 80%;
  border-radius: 40px;
  border: none;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 1.3px;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
  margin: 5% auto;
}

/* Color options */

.reservation-form button:hover {
  color: #b23000;
  background-color: #fff;
}

/* Layout and UI */

@media (min-width: 768px) {
  .reservation-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50vw;
    margin: 5% auto;
    padding: 6%;
    background: #230b0093;
    border: 2px solid #de4a00;
    border-radius: 9px;
  }

  .reservation-container {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .form-label {
    flex: 1;
    margin-right: 1rem;
  }

  .form-input,
  .form-select {
    width: 50%;
  }

  .form-select#motorcycle_id {
    width: 50%;
  }

  button {
    width: auto;
  }
}

@media (min-width: 1200px) {
  .reservation-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40vw;
    margin: auto;
    padding: 2rem;
    background: #230b0093;
    border: 2px solid #de4a00;
    border-radius: 9px;
  }

  .reservation-form label {
    font-size: calc(0.4rem + 1vw);
    font-weight: bold;
    color: white;
    line-height: 2rem;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .reservation-form input,
  .reservation-form select {
    width: 100%;
    font-size: calc(0.4rem + 1vw);
    padding: calc(0.3rem + 1vw);
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 7px;
    color: #fff;
    outline: none;
    background: rgba(224, 219, 219, 0.245);
    font-family: 'Trebuchet MS', sans-serif;
  }

  .form-input,
  .form-select {
    width: 100%;
    margin-top: 2%;
  }

  .form-select#motorcycle_id {
    width: 100%;
  }
}

/* Other styles */

.form-input {
  padding: calc(0.3rem + 1vw);
  font-size: 1rem;
}

.form-select {
  padding: 0.5rem;
  font-size: 1rem;
}

@media only screen and (max-width: 768px) {
  .reservation-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70vw;
    margin: 15% auto;
    padding: 5%;
    background: #230b0093;
    border: 2px solid #de4a00;
    border-radius: 9px;
  }

  .form-input {
    padding: calc(0.2rem + 1vw);
    font-size: 1rem;
  }

  .form-select {
    padding: 0.5rem;
    font-size: 1rem;
  }
}
