.rating-heading {
  font-size: 20px;
  color: rgb(255, 64, 0);
}

.default-heading {
  font-size: 18px;
  color: gray;
}

.star {
  cursor: pointer;
  border: none;
  background: none;
  font-size: 24px;
}

.star.selected {
  font-size: 24px;
  color: #ffa500;
}

@keyframes celebration {
  0% {
    transform: rotate(0);
    opacity: 1;
  }

  50% {
    transform: rotate(360deg);
    opacity: 0.5;
  }

  100% {
    transform: rotate(0);
    opacity: 1;
  }
}

.celebration-animation {
  animation-name: celebration;
  animation-duration: 1s;
  animation-iteration-count: 3;
  animation-timing-function: linear;
}
